.CircularProgressbar .CircularProgressbar-path {
    stroke: #ff2d47 !important;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #ff2d47 !important;
}

.model3d{
    margin-top: 40px;
}

#canvas{
    position: relative;
    margin-top: 10px;
}

.container3d {
    width: 100% !important;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.play-btn svg {
    width: 75px;
    height: 75px;
}

.stop-btn {
    display: none;
}

.menu a:hover {
    opacity: 0.7;
}

.step-controls {
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    top: 30px;
    flex-direction: column;
}

.system{
    display: flex;
    justify-content: center;
    align-items: center;
}

.digits{
    display: flex;
}

.step-controls-play{
    bottom: 30px;
    top: unset;
}
  
.step-controls .step.selected.start-text {
    color: #5700ff;
}

.step-controls .step {
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-controls .step .step-text {
    height: 30px;
    width: 30px;
    background-color: #ff2d47;
    border-radius: 50%;
    display: inline-block;
    color: transparent;
}

.step-controls .step .step-text.small {
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.step-controls .step .step-text.rect {
    height: 3px;
    border-radius: 0;
}

.step-controls .step .step-text:not(.rect):hover {
    opacity: 0.7;
    cursor: pointer;
}

.step-controls .step:first-of-type::before {
    visibility: hidden;
}

.menu {
    display: flex;
    margin: auto;
    justify-content: space-around;
    width: 100%;
    max-width: 100%;
}

.menu a {
    z-index: 100;
    text-decoration: none;
}

.play-btn{
    width: 5vw;
    cursor: pointer;
}

.purpleBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8vw;
    height: 85px;
    text-decoration: none;
    color: #ff2d47;
    background-color: transparent;
    border: 3px solid #ff2d47;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    border-radius: 40px;
    display: inline-flex;
    font-family: 'Eina-semi'!important;
    font-weight: 700;
    font-size: 30px;
}

.purpleBtn:hover {
    background-color: #ff2d47;
    color: #ffffff;
}

.loader3D{
    width: 12%;
    position: absolute;
    top: 35%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media screen and (min-width: 2580px) {
    .purpleBtn {
        height: 130px;
        font-size: 40px;
    }

    .step-controls .step .step-text {
        height: 35px;
        width: 35px;
    }
    
    .step-controls .step .step-text.small {
        height: 25px;
        width: 25px;
    }
}

@media screen and (max-width: 1570px) {
    .purpleBtn {
        width: 10vw;
        height: 75px;
        font-size: 26px;
    }

    .step-controls .step .step-text {
        height: 25px;
        width: 25px;
    }
    
    .step-controls .step .step-text.small {
        height: 15px;
        width: 15px;
    }
}

@media screen and (max-width: 1200px) {
    .purpleBtn {
        width: 10vw;
        height: 68px;
        font-size: 20px;
        border: 2px solid #ff2d47;
    }
    
    .play-btn{
        width: 6vw;
    }
}

@media screen and (max-width: 1000px) {
    .purpleBtn {
        width: 11vw;
        height: 50px;
        font-size: 15px;
    }

    .play-btn{
        width: 7vw;
    }

    .step-controls {
        top: 15px;
    }

    .step-controls-play{
        bottom: 15px;
        top: unset;
    }
}

@media screen and (max-width: 670px) {
    .purpleBtn {
        height: 37px;
        font-size: 13px;
    }
    
    .step-controls .step .step-text {
        height: 15px;
        width: 15px;
    }
    
    .step-controls .step .step-text.small {
        height: 10px;
        width: 10px;
    }
}

@media screen and (max-width: 450px) {
    .purpleBtn {
        height: 32px;
        font-size: 12px;
        border: 1px solid #ff2d47;
    }
}

@media screen and (max-width: 380px) {
    .purpleBtn {
        height: 22px;
        font-size: 9px;
    }
    
    .play-btn{
        width: 9vw;
    }
}