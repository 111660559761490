@media (min-width: 992px) {
    .col-lg-learning-6 {
        flex: 0 0 auto;
        width: 50%;
        max-width: 50%;
    }
}
.scroll-container {
    position: relative;
    overflow-x: hidden; /* Hide default scrollbar */
    width: 100%;
  }
  
  .scroll-content {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px; /* Adjust spacing between cards */
    padding: 10px; /* Optional: Add padding */
    transition: all 0.3s ease-in-out; /* Optional: Add smooth transition */
  }
  
  .card {
    /* Styles for your cards */
    flex: 0 0 auto;
    width: 200px; /* Set a fixed width for each card */
    height: 150px; /* Set a fixed height for each card */
    background-color: #f0f0f0; /* Optional: Background color */
    border-radius: 5px; /* Optional: Add rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  }
  
  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .scroll-button.left {
    left: 0;
  }
  .img-container {
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
    border: 5px solid #ffffff;
    border-radius: 10px;
    padding-bottom: 20px;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    box-sizing: content-box;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}
.img-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
  
  .scroll-button.right {
    right: 0;
  }
  
  .scroll-button:hover {
    color: #333; /* Change color on hover */
  }
  
.col-learning-12 {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
}

.row_learning {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.75rem;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    padding-top: 40px;
}

.learning_img {
    max-width: 80%;
    height: auto;
}
.doc_img {
    max-width: 100%;
    height: auto;
}

.learning_container {
    width: 100%;
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .learning_container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .learning_container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .learning_container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .learning_container {
        max-width: 1140px;
    }
}

.def_txt_1 {
    background: #ebf3ff;
    color: #0056d2;
    width: fit-content;
    font-size: 12px;
    padding: 3px;
}
.def_txt_2{
    margin-top: 10px;
    line-height: 42px;
    font-size: 30px;
}
.def_txt_3{
    margin-top: 20px;
    line-height: 28px;
    font-family: 'Eina-light' ;
    font-size: 16px;
    width: 80%;
}
.img_txt{
    font-family: 'Eina-light' ;
    font-size: 12px ;

}
@media (max-width: 450px) {
    .def_txt{
        display: flex;
        justify-content: center;
    }
    .def_txt_1 {
        text-align: center;
        font-size: 12px;
        padding: 3px;

    }
    .def_txt_2{
        text-align: center;
        margin-top: 10px;
        line-height: 34px;
        font-size: 26px;
    }
    .def_txt_3{
        width: 100%;
        text-align: center;
    }
    .learning_img{
        margin-top: 20px;
        max-width: 100%;
    }
    .doc_img {
        max-width: 100%;
        height: auto;
    }

}