.treatment-approach-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.treatment-approach-box {
    margin-top: 10px;
    border: 1px solid #B6B6B6;
    background-color: #FAFAFA;
    border-radius: 5px;
}

.treatment-approach-title {
    font-weight: 600 !important;
    color: #363636;
}

.treatment-approach-select-title {
    color: #363636;
    font-family: Eina-light !important;
}

.info-arches-container {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
}

.info-arches-InputLabel {
    font-size: 14px !important;
}

.info-images-container {
    display: flex !important;
    flex-direction: row !important;
    padding: 1rem;
    justify-content: space-evenly;
    align-items: stretch;
}

.info-dentition-accordion-btn {
    display: flex;
    padding: 5px;
    background-color: #FAFAFA;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    flex-wrap: wrap;
    border-radius: 5px;
}

.info-single-image-container {
    width: 340px;
    height: 303px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-single-image-info, .info-checkbox-group-info {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.info-single-image-img {
    width: 100%;
    height: 100%;
}

.info-single-image-info-content {
    background-color: #fff;
    height: 80%;
    overflow-y: scroll;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .5);
    border: none;
    border-radius: 15px;
    position: relative;
    padding: 15px 20px;
    color: #36393a;
    font-size: 14px;
}

.info-single-image-details, .info-checkbox-group-details {
    font-family: Eina-light !important;
}

.info-checkboxes-group-box {
    padding: 18px;
    overflow: hidden;
    background-color: #fafafa;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.info-checkbox-group-box {
    position: relative;
    width: 30%;
    height: auto;
    display: flex;
    text-align: center;
    flex-flow: column;
    border: 2px solid #898686;
    margin: 5px;
    border-radius: 15px;
    cursor: pointer;
    justify-content: space-around;

    @media screen and (max-width: 550px) {
        width: 100%;
    }
}

.info-checkbox-group-info-box {
    padding-bottom: 10px;
    &:hover {
        opacity: 0.6;
    }
}

.info-checkbox-group-selected-box {
    border: 2px solid #FA2C46;
    box-shadow: 0 0 5px #FA2C46;
}

.info-checkbox-group-disabled-box {
    border-color: #acacac;
    opacity: 0.6;
}

.info-checkbox-group-img {
    width: 100%;
    height: 210px;
}

.info-checkbox-group-info-content {
    position: relative;
    background-color: #fff;
    height: 237px;
    overflow-y: scroll;
    border: none;
    border-radius: 15px;
    padding: 15px 20px;
    color: #36393a;
    font-size: 14px;
}

.additional-instructions-teeth-container {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
}

.additional-instructions-teeth-wrapper {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.additional-instructions-teeth-svg-div {
    width: 50%;
}

.additional-instructions-teeth-svg {
    width: 100%;
    height: auto;
}

.additional-instructions-teeth-svg path {
    cursor: pointer;

    &:hover {
        fill: #fea3a3 !important;
    }
}

.additional-instructions-tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
    justify-content: center;
}

.additional-instructions-tag-container {
    cursor: default;
    display: inline-flex;
    align-items: center;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 7px;
    border-radius: 16px;
    background-color: #e0e0e0;

    &:hover {
        opacity: 0.8;
    }
}

.additional-instructions-tag {
    color: #36393a;
    font-size: 14px !important;
}

.additional-instructions-tag-close {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.additional-instructions-title-container {
    display: flex;
    column-gap: 5px;
}

.additional-instructions-info {
    cursor: pointer;
    font-size: 15px !important;

    &:hover {
        opacity: 0.8;
    }
}

.additional-instructions-teeth-title {
    font-size: 15px;
    color: #363636;
    margin-bottom: 10px;
}

.additional-instructions-teeth-direction-container {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.additional-instructions-teeth-direction {
    font-size: 18px;
    color: #363636;
}

.info-v2-textfield input {
    font-size: 14px;
}

.info-finding-col-1-title {
    color: #363636;
    width: 35%;
    font-family: Eina-light !important;
}