/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
    @font-face {
      font-family: "Eina-light";   /*Can be any text*/
      src: local("AirbnbCerealLight"),
        url("/public/fonts/2020/Eina03-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Eina-semi";   /*Can be any text*/
        src: local("AirbnbCerealLight"),
        url("/public/fonts/2020/Eina03-SemiBold.ttf") format("truetype");
    }

    @font-face {
        font-family: "Eina-bold";   /*Can be any text*/
        src: local("AirbnbCerealLight"),
        url("/public/fonts/2020/Eina03-Bold.ttf") format("truetype");
    }

  @font-face {
    font-family: 'Almarai';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/public/fonts/2020/tssoApxBaigK_hnnS_antnqWo572.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Almarai-semi';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/public/fonts/2020/tsstApxBaigK_hnnQ1iFo0C3.woff2') format('woff2');
  }
  
  @font-face {
    font-family: 'Almarai-bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/public/fonts/2020/tssoApxBaigK_hnnS-agtnqWo572.woff2') format('woff2');
  }
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    text-transform: none;
    font-weight: 'bold';
    font-size: 14px;
    font-family: 'Eina-semi', sans-serif;
}
.MuiTableCell-head, .MuiTab-wrapper{
    font-family: 'Eina-semi'!important;
    font-size: 14px;
}

body{
    background-color: #f6f6f7!important;
}
.language {
    font-family: 'Almarai-semi'!important;
}
.ar {
    .language {
        font-family: 'Eina-semi'!important;
    }
}
.MuiPickersToolbar-toolbar{
    background-color: #ff2c46!important;
}
.MuiPickersModal-withAdditionalAction .MuiButton-textPrimary{
    color: #ff2c46!important;
}
.MuiTab-textColorSecondary.Mui-selected {
    color: #ff2c46!important;
}
.makeStyles-today-1731{
    font-family: 'Eina-semi'!important;
    font-size: 14px!important;
}
.PrivateTabIndicator-root-20 {
    height:4px!important;
}
.MuiPickersDay-daySelected{
    background-color: #ff2c46!important;
}
.MuiTab-textColorSecondary{
    color: black!important;
}
.MuiMenuItem-root{
    font-family: 'Eina-light'!important;
    font-size: 14px!important;
}
.makeStyles-datePicker-1533 input{
    font-size: 27px!important;
    font-family: 'Eina-semi'!important;
}
.MuiTypography-body1{
    font-size: 14px!important;
    font-family: 'Eina-semi'!important;
}
.bold-weight {
    font-family: 'Eina-bold' !important;
    .ar & {
        font-family: 'Almarai-bold' !important;
    }
}
label.Mui-focused{
    color: #ff2c46!important;
}
.MuiChip-label{
    font-family: 'Eina-semi'!important;
    font-size: 14px !important;
}
.MuiTableCell-body {
    font-family: 'Eina-light'!important;
    font-size: 14px !important;
}
.MuiTableCell-alignCenter:nth-child(1) {
    text-align: left!important;
}

.one-heading-table .MuiTableCell-alignCenter:nth-child(1) {
    text-align: center !important;
}
.DateRangePicker__CalendarSelection{
    background-color: #ff2c46!important;
    border: 1px solid #ff2c46;
}
.DateRangePicker {
    padding:15px!important;
    border-radius: 25px;
}
.MuiList-padding {
    padding-top: 0!important;
    padding-bottom: 0!important;
}
.Polaris-Button--primary {
    background-color: #ff2c46!important;
}
.Polaris-Button--plain {
    color: black!important;
}
#videoDiv > span > img{
    max-width: 60%;
}
.scroll-spy-active {
    color: #ff2c46!important;
    margin-left: 5px;
    transition: .1s ease-in-out;
    font-weight: bold!important;
  }
 
 .DateRangePicker__Date--today .DateRangePicker__DateLabel{
    color:#fff!important;
    position: absolute!important;
    top:13%!important;
    background-color: #f76677;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    bottom: 13%!important;
    left:0%!important;
    right:0%!important;
    border-radius: 5px;
}
.date-time-picker{
    width: 100% !important;
}
.date-time-picker  div{
    display: flex;
    justify-content: space-between;
    width: 22rem
}

.date-time-picker  div label span{
    display: none
}

.date-time-picker  div label select{
    height: 38px;
    width: 118.2px;
    text-align: center;
    border: none;
    opacity: 0.52;
    background-color:rgba(211,215,219,0.25);
    color: #303030;
}

.date-time-picker  div label select:focus{
   outline: none;
}
.ar *{
      font-family: 'Almarai-semi' !important;
      font-weight: 400;
}
.ar .MuiTypography-body1, 
.ar .MuiTableCell-head,
.ar .MuiTab-wrapper,
.ar .makeStyles-today-1731,
.ar .MuiMenuItem-root,
.ar .makeStyles-datePicker-1533 input,
.ar .MuiTypography-body1,
.ar .MuiChip-label,
.ar .MuiTableCell-body,
.ar .MuiAlert-message{
    font-family: 'Almarai-semi' !important;
}

.MuiAlert-message{
    width: 100%;
}

.MuiLinearProgress-bar{
    background-color: rgb(255,44,70)!important;
}
.MuiLinearProgress-determinate{
    background-color: rgb(255,44,70, 0.4)!important;
}

.add-patient-input-wrapper, .add-patient-input-wrapper-mall-collab {
    display: flex;
    flex-direction: column;
    width: 49%;
}

.add-patient-input-wrapper-mall-collab {
    width: 30%;
}

.add-patient-input-text-phone .PhoneInputInput{
    color: #303030;
    border: none;
    border-bottom: 2px solid #CACACA;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    outline: none;
    padding: 0.7rem 0.5rem;
}

.add-patient-input-text-phone .PhoneInputInput::placeholder{
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif" !important;
}

@media screen and (max-width: 1200px) {
    .add-patient-input-wrapper, .add-patient-input-wrapper-mall-collab {
        width: 100%;
    }
}