.card-wrapper {
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.card-header {
    grid-row-gap: 0px;
    background-color: #fff;
    border-radius: 16px;
    flex-direction: column;
    width: 300px;
    height: 100%;
    padding: 0 0 24px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .1);

}

.redesign_content-card-image {
    position: relative;
}

.card_img {
    max-width: 100%;
    height: 200px;
    object-fit: contain;
    display: inline-block;
}

.redesign_content-card-body {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    padding: 24px 24px 0;
    display: flex;
}

.redesign_content-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.redesign_content-card-type-container {
    flex-direction: row;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.redesign_content-card-type.ondemand-webinar-color {
    background-color: #89d6b3;
}

.redesign_content-card-type.upcoming-webinar-color {
    background-color: #9fc0f2;
}

.redesign_content-card-type {
    width: fit-content;
    background-color: #ffd1b0;
    border-radius: 16px;
    flex-direction: row;
    flex: 0 auto;
    padding: 6px 10px;
    font-size: 14px;
    line-height: 100%;
    display: block;
    font-weight: 500;
}
.arrow-img{
    width: 15px;
    height: 15px;
    background-color: transparent;
    cursor: pointer;
}
.ar {
    .arrow-img {
        transform: rotate(180deg);
    }
}
.redesign_heading-h4 {
    color: #000;
    text-align: left;
    text-transform: none;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
}
.ar {
    .redesign_heading-h4 {
        text-align: right;
    }
}

.redesign_content-card-author-role {
    font-size: 12px;
    font-family: 'Eina-light';
    color: rgba(0, 0, 0, .9);

    font-size: 14px;
    font-weight: 400;
    line-height: 150%;

}

.subcategories-list-wrapper {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    /* gap: 1rem; */
    padding: 1px;
    list-style: none;
    padding-top: 20px;
    padding-bottom: 20px;
}
.attachment_png{
    width: 23px;
    height: 23px;
}
.paragon__chip {
    align-items: center;
    border: .0625rem solid #000;
    border-radius: .4375rem;
    display: inline-flex;
    font-size: 15px;
    font-weight: 400;
    justify-content: center;
    line-height: 1.5rem;
    margin-inline: .25rem;
    padding: .125rem .5rem;
    text-align: center;
    vertical-align: initial;
    white-space: nowrap;
    background-color: #fff;
    border-color: #f2f0ef;
    color: black;
    cursor: pointer;
}

.list-inline-item {
    flex-wrap: nowrap;
    gap: 1rem;
    padding: 1px;
    background-color: white;

}
.button-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 103%;
    z-index: 2;
}
.card-arrows {
    display: flex;
    justify-content: space-between;
    
}
.paragon__chip_selected{
    background-color: #fff;
    border-color: #002121;
    color: #002121;
}
.redesign_content-card-collection-list {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-flow: wrap;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: stretch;
    justify-items: stretch;
    width: auto;
    display: grid;
}

@media screen and (min-width: 800px) {
    
    .redesign_content-card-collection-list {
    grid-template-columns: 1fr 1fr ;
    }

}
@media screen and (min-width: 800px) {
    
    .redesign_content-card-collection-list {
    grid-template-columns: 1fr 1fr;
    }

}
@media screen and (min-width: 1300px) {
    .redesign_content-card-collection-list {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        grid-template-columns: 1fr 1fr 1fr ;
    }
}
@media (max-width: 479px) {
    .redesign_content-card-collection-list {
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        grid-template-columns: 1fr;
    }
    .card-header {
        min-width: 200px;
        max-width: none;
        width: 100%;
    }

    .redesign_content-card-type {
        font-size: 12px;
    }

    .redesign_heading-h4 {
        font-size: 16px;
    }
    .redesign_content-card-author-role {
        color: rgba(0, 0, 0, .7);
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.5;
    }
}