
  .sidebar {
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: white;
    position: fixed;
    height: 100%;
    overflow: auto;
    border-right:1px solid rgba(207, 207, 207, 0.45);
    overflow: hidden;
  }

  .content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 100;
    background-color: white;
  }
  .content-ar{
    margin-right: 200px;
    padding: 1px 16px;
    height: 100;
    background-color: white;
  }
  .sidebar div {
    display: block;
    color: black;
    padding: 9px;
    cursor: pointer;
    border-radius: 27px;
    margin:10px;
    font-size: 16px !important;
  }
  .sidebar span {
    font-size: 16px !important;
  }

  .side-bar-single-container {
    display: flex !important;
  }

  .side-bar-single-container-no-img {
    margin-left: 29px !important;
  }

  .side-bar-single-img {
    width: 18px;
    height: 18px;
  }

  .sidebar div.active {
    background-color: rgba(207, 207, 207, 0.45);
    color: #ff2c46;
    border-radius: 7px;
   
  }
  .sidebar img.activeimg {
    filter: invert(41%) sepia(62%) saturate(4597%) hue-rotate(331deg) brightness(97%) contrast(125%);
    opacity: 1 !important;
  }
  
  .sidebar div:hover:not(.active) {
    background-color: rgba(207, 207, 207, 0.45);
    border-radius: 7px;
    
  }
  .sidebar img:hover:not(.activeimg) {
    
    filter: invert(41%) sepia(62%) saturate(4597%) hue-rotate(331deg) brightness(97%) contrast(125%);
    opacity: 1 !important;
  }
  

  .sub_menu{
    margin-inline: 35px  !important;
  }
  @media screen and (max-width: 700px) {
    .sidebar {
      overflow: auto;
      width: 100%;
      height: auto;
      position: relative;
      display: flex;
    }
    .sidebar div {float: left;}
    .content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sub_menu{
      margin-inline: 15px  !important;
    }
    .sidebar div {
      text-align: center;
      float: none;
    }
  }
  