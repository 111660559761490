.faq_accordion {
    width: 50%;
    margin: 15px auto;
    padding: 15px;
    background-color: hsla(0, 0%, 84.7%, .0745098) !important;
}

.faq_accordion-item {
    padding: 15px;
    background-color: hsla(0, 0%, 84.7%, .0745098) !important;
    margin-bottom: 15px;
}

.faq_accordion-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
}

.faq_accordion-title {
    margin: 0;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    color: #404040;
    font-weight: bold;
}

.faq_accordion-content {
    padding: 10px;
    /* font-family: 'Eina-light';
    color: rgba(0, 0, 0, .9);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; */
    font-size: 14px;
    font-family: 'Eina-semi', sans-serif;

}

/* .faqNewMainTitle{
    padding-top: 100px;
    font-size: 14px ;
    color: #ff2c46;
    line-height: 25px;
    font-weight: 700 ;
    text-align: center;
} */
.faqNewMainSubTitle {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    margin-bottom: 16px;
    text-align: center;
    padding-top: 100px;
}
@media screen and (max-width: 800px) {
    .faq_accordion {
        width: 100%;
    }
}
@media screen and (max-width: 400px) {
    .faq_accordion {
        width: 100%;
    }

    .faq_accordion-title {
        font-size: 14px;
    }
}