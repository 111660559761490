iframe.threed-iframe{
    width: 100%;
    height: 550px;
}

@media screen and (max-width: 1200px){
    iframe.threed-iframe{
      height: 350px;
    }
}

@media screen and (max-width: 980px){
    iframe.threed-iframe{
      height: 500px;
    }
}

@media screen and (max-width: 766px){
    iframe.threed-iframe{
      height: 300px;
    }
}

@media screen and (max-width: 500px){
    iframe.threed-iframe{
      height: 250px;
    }
}
